<template>
  <div>
    <template v-if="type === 'slider'">
      <LayoutSwipeList class="mb-10">
        <SwiperSlide v-for="(item, index) in items" :key="index">
          <BirthdayTeaser :item="item" :index="index" type="slider" />
        </SwiperSlide>
      </LayoutSwipeList>
    </template>
    <template v-else>
      <CommonListLabel
        v-if="placement === 'side'"
        :icon="NavnenytIcon"
        label="Birthdays"
        :link="isMm ? undefined : currentPaperUrl + '/' + $t('Routes.Birthdays')"
      />
      <CommonDivider />

      <ul class="text-red">
        <template v-for="(item, index) in items" :key="'li' + index">
          <li>
            <BirthdayTeaser
              :item="item"
              :placement="placement"
              :index="index"
            />
            <CommonDivider />
          </li>
        </template>
      </ul>
    </template>
  </div>
</template>

<script setup lang="ts">
import { SwiperSlide } from 'swiper/vue'
import NavnenytIcon from '~/assets/icons/navnenyt.svg?url'
const { isMm } = useBrand()
const indexStore = useIndexStore()
const nuxtApp = useNuxtApp()

const props = defineProps({
  count: {
    type: Number,
    default: 5,
  },
  type: {
    type: String,
    default: 'default',
  },
  placement: {
    type: String,
    default: 'side',
  },
})

const paperId = indexStore.currentPaper ? indexStore.currentPaper.RecordId : 1

const { data: items } = await useAsyncData(
  `${props.count}-${paperId}`,
  async () => {
    return await nuxtApp.$api.content.birthdays(paperId, props.count)
  }
)

const currentPaperUrl = computed(() => {
  return indexStore.currentPaper?.RecordId === 1
    ? ''
    : `/${indexStore.currentPaper?.Url}`
})
</script>
